.gradient-button {
    background: linear-gradient(90deg, rgb(211, 238, 247) 0%, rgb(235, 189, 235) 50%, rgb(197, 212, 231) 100%);
    background-size: 200% 200%;
    color: black; /* Adjust if needed to improve visibility */
    margin: 1rem;
    padding: 1rem 5rem;
    border: none;
    border-radius: 1rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: background-position 0.5s, color 0.5s;
    cursor: pointer;
    outline: none;
}

.gradient-button:hover {
    background-position: right center;
    color: rgb(51, 51, 51);
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .gradient-button {
        padding: 1rem 4rem; /* Smaller padding for smaller screens */
        font-size: 1.5rem; /* Adjust font size for readability */
    }
    .title {
        font-size: 2.5rem;
    }
}

@media (max-width: 480px) {
    .gradient-button {
        padding: 1rem 3rem; /* Even smaller padding for very small screens */
        font-size: 1.4rem; /* Further reduce font size for small screens */
    }
    .title {
        font-size: 3.5rem;
        text-align: center;
    }
}
.title {
    font-size: 6rem; /* Equivalent to text-6xl in Tailwind CSS */
    font-weight: bold; /* Equivalent to font-bold in Tailwind CSS */
    color: white; /* Default text color */
    margin-bottom: 3.5rem; /* Equivalent to mb-14 in Tailwind CSS */
    transition: color 0.5s; /* Smooth transition for color change */
}

.title:hover {
    background: linear-gradient(90deg, rgba(173, 216, 230, 1) 0%, rgba(221, 160, 221, 1) 50%, rgba(176, 196, 222, 1) 100%);
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}



