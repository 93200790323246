.navbody {
    background-color: rgb(15, 195, 240);
    display: flex;
     padding-left: 10%;
    padding-right: 10%;
     
    height: 10vh;

}

.navcontainer {
    display: flex;
    flex-direction: row;
   
    margin-top: auto;
    margin-bottom: auto;
    background-color: red;
    width:100%;
    justify-content: space-between;
}

.navbtn {
    height:"100%";
}