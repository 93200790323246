.container {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 20px auto;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #3b82f6;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;

  }

  33% {
    opacity: 1;
    transform: translateY(30px);
  }

  67% {
    opacity: 1;
    transform: translateY(40px);
  }

  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  margin-top: 75px;
  margin-bottom: 30px;
  margin-left: -30px;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: rgb(63, 37, 206);
  text-transform: uppercase;
  white-space: nowrap;
  opacity: .25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

.container {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 20px auto;
}

@media screen and (max-width: 700px) {
  .container {

    margin: 70px auto;
  }

  .chevron {

    width: 20px;
    height: 6px;

  }
}

/* Add this CSS to your stylesheet */
.profile-link {
  position: relative;
  overflow: hidden;
}

.profile-link img {
  transition: filter 0.3s;
}

.profile-link:hover img {
  filter: blur(8px);
}

.profile-link p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s;
  opacity: 0;
font-size: 25px;
font-weight: thin;
  color: #ffffff; /* Adjust text color as needed */
}

.profile-link:hover p {
  opacity: 1;
}
