.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginform {

    display: flex;
    flex-direction: column
}



.loginform input {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 90%;
    height: 30px;
    padding-left: 20px;
    margin: 10px
}

.loginform button {
    background-color: #2a5ce6;
    border-radius: 10px;
    width: 90%;
    color: white;
    height: 30px;
    margin: 10px;
}

.allcon {
    box-shadow: 2px 7px 17px -6px rgba(0, 0, 0, 0.651);
    -webkit-box-shadow: 2px 7px 17px -6px rgba(0, 0, 0, 0.63);
    -moz-box-shadow: 2px 7px 17px -6px rgba(0, 0, 0, 0.616);
    background-color: #f4f4f4b5;
    border: 1px solid transparent;
    border-radius: 20px;
    padding: 20px;
    width: 30%;
}

@media only screen and (max-width:500px) {
    .allcon {
        width: 90%;
    }
}

* {
    outline: none;
}

.logcon {

    width: 100%;
    height: 90vh;

    padding: 10px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;

}

.btncontainar {
    align-content: center;
    margin-bottom: 10px;
    text-align: center;
}

.btncontainar button {
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    margin: 5px;
    width: 45%;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 10px;
    box-shadow: 2px 7px 17px -6px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: 2px 7px 17px -6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 7px 17px -6px rgba(0, 0, 0, 0.28);

}