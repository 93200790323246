.modares {
    width: 400px;
    display: flex;
}
 
@media screen and (max-width: 700px) {
.modares {
    width: 80vw;
    display: flex;
    flex-direction: column;
}
}
