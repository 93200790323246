@media print {
  @page {
    margin: 0mm;
    /* Adjust this as needed */
  }



  #print-section,
  #print-section * {
    visibility: visible;
  }

  #print-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    transform: scale(3);
    /* Adjust this as needed */
    transform-origin: top left;
  }

  body,
  html {
    width: 210mm;
    height: 300px;
    overflow: hidden;
  }
}

.gradient-button2 {
  background: linear-gradient(90deg, rgb(211, 238, 247) 0%, rgb(235, 189, 235) 50%, rgb(197, 212, 231) 100%);
  background-size: 200% 200%;
  color: black;
  margin-right: 0;
  border: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: background-position 0.5s, color 0.5s;
  cursor: pointer;
  outline: none;
}

.gradient-button2:hover {
  background-position: right center;
  color: rgb(51, 51, 51);
}

.submitBtn {
  background: linear-gradient(90deg, rgb(71, 71, 71) 0%, rgb(48, 48, 48) 50%, rgb(20, 20, 20) 100%);
  background-size: 200% 200%;
  color: white;
  margin-right: 0;
  border: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: background-position 0.5s, color 0.5s;
  cursor: pointer;
  outline: none;
}

.submitBtn:hover {
  background-position: right center;
}

/* Example media query for screens smaller than 768px */
@media (max-width: 768px) {
  .gradient-button2 {
    padding: 1rem 3rem;
    /* Smaller padding on smaller screens */
    font-size: 0.8rem;
    /* Smaller font size if needed */
  }
}

.a4-size {
  width: 50vw;
  /* 50% of the viewport width */
  height: calc(50vw * 1.4142);
  /* Height maintaining A4 aspect ratio (1.4142) */
  background-color: white;
  /* White background */
  border: 1px solid black;
  /* Adds a border for visibility */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Optional: Adds a subtle shadow for depth */
  max-width: 400px;
  /* Maximum width to prevent divs from getting too large */
  max-height: calc(400px * 1.4142);
  /* Maximum height maintaining aspect ratio */
}

.imgsetupCv {
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  width: 55px;
  box-shadow: 0 0 5px rgb(129, 129, 129);
  aspect-ratio: 1/1;
  object-fit: cover;
  margin: 0 auto;

}

.inputs {
  border: none;
  outline: none;
  font-size: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  padding-top: 4px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: transparent;
}

.link-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.link-container a {
  display: hidden;

}


.link-container .qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-smaller-spacing {
  line-height: 0.5;
  /* Smaller line height for reduced spacing */
  font-size: 10px;
  /* Maintaining the font size as per your inline style */
}

.border-left-accent {
  border-left: 8px solid #22d3ee;
  padding-left: 8px;
  /* Adjust padding as needed */
}