*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-thumb {
    width: 5px;
    background: rgb(41, 152, 237)
}

.biocontainar {
    padding: 0 60px;
    width: 56vw;
    display: flex;

}

.biocontainar textarea {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 50%;
    height: 150px;
    padding-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.uplod {
    background-color: #2a5ce6;
    color: white;
    margin: 10px;
    border-radius: 10px;
    padding: 8px 30px;
    width: 90%;
}


.back {
    padding-bottom: 0 !important;

}

.imgsetup {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    width: 150px;
    
    aspect-ratio: 1/1;
    object-fit: cover;
    margin: 0 auto;

}

.imgsetup3 {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    width: 100px;
    aspect-ratio: 1/1;
    object-fit: cover;
    margin: 0 auto;
}

.imgsetup2 {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin: 0 auto;

}

.dform {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0 5px;
    display: flex;
    width: 40vw;
    gap: 30px;
    margin: 20px 10%;
justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subadd {
    display: flex;

    width: 50vw;

    justify-content: center;
}

.subadd button {
    background-color: #49494b;
    padding: 8px 40px;
    margin-right: 4px;
    border-radius: 10px;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 16ox;
}

.dform input {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 90%;
    height: 30px;
    padding-left: 20px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.social_name {
    width: 60% !important;

}

.bio {
    width: 60% !important;
    height: 40px;

    border-radius: 10px;
    padding-left: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding-left: 20px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dform select {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 60%;
    height: 30px;
    padding-left: 20px;
    margin: 10px
}

/* .setcol1 {
    width: 66.666%;
   
} */

.iphonecon {

    width: 50%;
}

.iphone {
    width: 100%;

}

.setupform {
    display: flex;
    flex-direction: column;

}

.screen {
    width: 45%;
    aspect-ratio: 5.8/13.1;
    border: 2px solid black;
    box-shadow: 5px 0px 65px -14px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 0px 65px -14px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 0px 65px -14px rgba(0, 0, 0, 0.75);
    border-radius: 1.5vw;
}

.modalcon {
    width: 600px;
    display: flex;
}

.sharebtn {
    background-color: #1d1d1d;
    padding-left: 10px;
    padding-right: 10px;
    height: 35px;

    margin-bottom: 20px;
    color: white;
    border-radius: 20px;
}

.sharebtnDesk {
    background-color: #1d1d1d;
    padding-left: 10px;
    padding-right: 10px;
    height: 35px;

    margin-bottom: 20px;
    color: white;
    border-radius: 20px;

}

.scr::-webkit-scrollbar {
    width: 0px;
}

.setcol3 {
    /* background-color: #f3f3f1; */
    width: 33.333%;

    flex-direction: column;

    align-items: center;

}

.dformcon {
    display: flex;

}

.delbtn {
    padding: 5px 8px;
    margin: auto 0;

}

.delbtn button {

    padding: 3px 20px;
    border-radius: 10px;
    color: white;
    background-color: rgb(204, 36, 36);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.qrcode {
    border-radius: 0px;
    height: auto;
    max-width: 100%;
    width: 100%;
}

.modalin {
    margin: 20px;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .modalin {
        margin: 20px;
        align-items: center;
    }

    .qrcon img {

        width: 200px;
        height: 200px;


    }

    .qrcode {
        border-radius: 0px;
        height: auto;
        max-width: 100%;
    }

    .dformcon {
        display: block;
    }

    .dform input {
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        width: 90%;
        height: 30px;
        padding-left: 20px;
        margin: 10px
    }

    .bio {
        width: 100% !important;

    }

    .social_name {
        width: 90% !important;

    }

    .dform select {
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        width: 90%;
        height: 30px;
        padding-left: 20px;
        margin: 10px
    }

    .container {
        flex-direction: column;


    }

    .setcol3 {
        width: 100vw;
    }

    .setcol1 {
        width: 100vw;

    }

    .setupform {
        width: 100vw;

    }

    .biocontainar {
        margin: 0;
        width: 100%;

        flex-direction: column-reverse;
    }

    .biocontainar textarea {
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        width: 100%;
        height: 70px;
        margin: 20px auto;
        padding-left: 20px;

    }



    .imgsetup {
        border-radius: 10px;
        width: 200px;

        margin: 20px auto;


    }

    .modalcon {
        width: 80vw;
        display: flex;
        flex-direction: column;
    }

    .subadd {
        display: flex;

        width: 70vw;
        justify-content: space-between;
        margin: 0px auto;
    }

    .subadd button {
        background-color: #10c9bf;
        padding: 8px 20px;
        border-radius: 10px;
        color: white;

    }

    .dform {

        padding: 0 0px;
        display: flex;
        flex-direction: column;
        width: 100vw;
        gap: 30px;
        margin: 15px 0;

    }

}








.qrcon img {
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 10px;
    margin: 20px auto;
}

.linkcon {

    text-align: center;
}

.qrcon a {
    background-color: #2a5ce6;
    color: white;
    padding: 5px 20px;
    border-radius: 10px;
    margin: 0px auto;
}