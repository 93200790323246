.gradient-button2 {
    background: linear-gradient(90deg, rgb(211, 238, 247) 0%, rgb(235, 189, 235) 50%, rgb(197, 212, 231) 100%);
    background-size: 200% 200%;
    color: black;
    margin-right: 0;
    border: none;   
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: background-position 0.5s, color 0.5s;
    cursor: pointer;
    outline: none;
}

.gradient-button2:hover {
    background-position: right center;
    color: rgb(51, 51, 51);
}
/* Example media query for screens smaller than 768px */
@media (max-width: 768px) {
    .gradient-button2 {
        padding: 1rem 3rem; /* Smaller padding on smaller screens */
        font-size: 0.8rem; /* Smaller font size if needed */
    }
}


.imgsetupHire {

    box-shadow: 0 0 5px rgb(129, 129, 129);
    object-fit: cover;
    
}

.divbg{
    background: rgb(255,255,255);
background: radial-gradient(circle, rgb(226, 222, 222) 2%, rgb(255, 255, 255) 98%);
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    background-color: #f0f0f0;
    color: #333;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #ddd;
  }
  
  .pagination .active {
    background-color: #007bff;
    color: white;
  }
  